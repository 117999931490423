<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4 mt--7">
                <div class="card-header">
                    <div class="row">
                        <div class="col-4">
                            <h3 class="mb-0">{{ tt('import_table') }}</h3>
                        </div>
                        <div class="col-8 text-right">
                            <input type="hidden" class="day_month_year" value="">
                            <div class="btn-group" role="group">
                                <select class="btn btn-outline-dark btn-sm" name="day_month_year" v-model="inputImportReport.table_download" @change="changeTableDownload">
                                    <option value="" selected>Choose Table</option>
                                    <option :value="der.table_name" v-for="der in dataImportReport" v-bind="der.table_name">{{der.table_name}}</option>
                                </select>
                            </div>
                            <a :href="'http://211d014de9b0.ngrok.io/smartcat_api_dev/public/'+'import/download-template-excel?table='+inputImportReport.table_download+'&token='+token" @click="downloadTemplate" v-if="!btnDownload.disabled">
                                <i class="fa fa-download btn btn-sm btn-default ml-5"></i>
                            </a>
                            <base-button class="btn btn-sm btn-default ml-5" size="small" type="dark" disabled v-else><i class="fa fa-download"></i></base-button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-5">
                            <label class="form-control-label">{{ tt('import_to') }} <span class="text-danger">*</span></label>
                            <file-input @change="changeImport" :ref="'file'+key"></file-input>
                        </div>
                        <div class="col-5">
                            <label class="form-control-label">{{ tt('table') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('table')">
                                <el-select class="select-danger" v-model="inputImportReport.table" :placeholder="tt('choose_table')">
                                    <el-option class="select-danger" :value="der.table_name" :label="der.table_name" v-bind:key="der.table_name"  v-for="der in dataImportReport"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-2">
                            <label style="color: transparent;">|</label>
                            <base-button class="col-12" size="large" type="primary" @click="importExcel" v-if="inputImportReport.table != '' && inputImportReport.excel != ''">{{ tt('import') }}</base-button>
                            <base-button class="col-12" size="large" type="primary" disabled v-else>{{ tt('import') }}</base-button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="card mb-4">
                <div class="card-header">
                    <div class="row">
                        <div class="col-4">
                            <h3 class="mb-0">{{ tt('import_errors') }}</h3>
                        </div>
                        <div class="col-8 text-right">
                            <a href="" class="btn btn-sm btn-dark" disabled="disabled">{{tt('export')}}</a>
                            <!-- <base-button size="sm" type="default" @click="importHistory">{{ tt('import_history') }}</base-button> -->
                            <router-link to="/report/import-history" class="btn btn-sm btn-default" tag="button">{{ tt('import_history') }}</router-link>
                            <!-- <a :href="'http://211d014de9b0.ngrok.io/smartcat_api_dev/public/'" class="btn btn-sm btn-dark">{{tt('import_history')}}</a> -->
                            <!-- <a href="#/report/import-history" class="btn btn-sm btn-dark">Import History</a> -->
                        </div>
                    </div>
                </div>
                <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data">
                    <el-table-column :label="tt('row')" :prop="tt('row')" min-width="200" sortable>
                        <template v-slot="{row}">
                            {{row.row}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('attribute')" :prop="tt('attribute')" min-width="200" sortable>
                        <template v-slot="{row}">
                            {{row.attribute}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('error_message')" :prop="tt('error_message')" min-width="200" sortable>
                        <template v-slot="{row}">
                            {{row.error_message}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('values')" :prop="tt('values')" min-width="200" sortable>
                        <template v-slot="{row}">
                            {{row.values}}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import importReport from '@/services/report/import.service';
    
    export default {        
        data() {
            return {     
                table: {
                    data: []
                }, 
                inputImportReport: {
                    table_download: '',
                    table: '',
                    excel: '',
                },
                btnDownload: {
                    disabled: true
                },
                btnImport: {
                    disabled: true,
                },
                dataImportReport: {},
                dataImportHistory: {},
                token: localStorage.token
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() {
                let context = this;
                Api(context, importReport.index()).onSuccess(function(response) {
                    context.dataImportReport = response.data.data
                    console.log(response.data.data)
                }).call();
            },
            importHistory() {
                let context = this;
                Api(context, importReport.import_history()).onSuccess(function(response) {
                    context.dataImportHistory = response.data.data
                    console.log(response)
                }).call();
            },
            changeImport(files) {
                this.inputImportReport.excel = files[0]
                console.log(this.inputImportReport)
            },
            changeTableDownload() {
                if (this.inputImportReport.table_download == '') {
                    this.btnDownload.disabled = true
                } else {
                    this.btnDownload.disabled = false
                }
            },
            importExcel() {
                let context = this;
                let formData = new FormData();

                formData.append('table', context.inputImportReport.table);
                formData.append('excel', context.inputImportReport.excel);

                console.log(formData)
                Api(context, importReport.import_table(formData)).onSuccess(function(response) {
                    // context.dataExportReport = response.data.data
                    console.log(response)
                }).onError(function(error) {
                    context.$notify({
                        message: 'Error',                  
                        type: 'red'
                    });
                }).onFinish(function() {
                }).call();
            }
        }   
    };
</script>
<style></style>
